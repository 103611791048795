/**
 * TableHeading
 * @module components/TableHeading
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Table.scss';

import Icon from 'components/Icon';

const TableHeading = ({ sortable, onSort, text, id }) => {
	const [sorting, setSorting] = useState(null);

	const className = sortable
		? 'table__heading table__heading--sortable'
		: 'table__heading';

	const icon =
		sorting === 'up'
			? 'icon-sort-desc'
			: sorting === 'down'
			? 'icon-sort-asc'
			: 'icon-sort';

	const handleSort = () => {
		const newSorting = sorting === 'up' ? 'down' : 'up';
		setSorting(newSorting);
		onSort(id, newSorting);
	};

	return (
		<th scope="col" className={className} onClick={onSort ? handleSort : null}>
			{text}
			{sortable && <Icon icon={icon} />}
		</th>
	);
};

TableHeading.propTypes = {
	id: PropTypes.string,
	sortable: PropTypes.bool,
	onSort: PropTypes.func,
	text: PropTypes.string,
};

export default TableHeading;
