/**
 * TableHead
 * @module components/TableHead
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Table.scss';

const TableHead = ({ children }) => {
	return (
		<thead>
			<tr className="table__head">{children}</tr>
		</thead>
	);
};

TableHead.propTypes = {
	caption: PropTypes.string,
};

export default TableHead;
