/**
 * MainMenu
 * @module components/MainMenu
 */

import React from 'react';
import './MainMenu.scss';
import { NavLink } from 'react-router-dom';
import { checkTokenValidity } from 'common/helpers';

import Icon from 'components/Icon';

const MainMenu = React.memo(() => {
	const isLoggedIn = checkTokenValidity();
	const onMobileDevice = window.matchMedia('(max-width: 767px)').matches;

	return (
		<nav className="mainmenu" aria-label="Huvudmeny">
			<ul className="mainmenu__list">
				<li className="mainmenu__list__item  mainmenu__list__item--first">
					<NavLink to="/" exact activeClassName="active">
						<Icon icon="icon-home" />
						<span>Hem</span>
					</NavLink>
				</li>
				{isLoggedIn && (
					<li className="mainmenu__list__item mainmenu__list__item--second">
						<NavLink to="/historik" exact activeClassName="active">
							<Icon icon="icon-archive" />
							<span>Föreningshistorik</span>
						</NavLink>
					</li>
				)}
				{isLoggedIn && onMobileDevice && (
					<li className="mainmenu__list__item">
						<NavLink to="/anvandare" exact activeClassName="active">
							<Icon icon="icon-user" />
							<span>Användare</span>
						</NavLink>
					</li>
				)}
				<li className="mainmenu__list__item">
					<NavLink to="/rapportera" activeClassName="active">
						<Icon icon="icon-layers" />
						<span>Rapportera</span>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
});

export default MainMenu;
