/**
 * StandardLayout
 * @module layouts/StandardLayout
 */

import React from 'react';

import PageHeader from 'components/PageHeader';
import Brain from 'components/Brain';
import { USER_INFO } from 'common/config';

const StandardLayout = ({ children, location }) => {
	const onMobileDevice = window.matchMedia('(max-width: 767px)').matches;
	const user = JSON.parse(localStorage.getItem(USER_INFO));

	const shouldShowMenu =
		location.pathname === '/' ||
		location.pathname === '/logga-in' ||
		location.pathname === '/bekraftelse' ||
		location.pathname === '/historik' ||
		location.pathname === '/anvandare' ||
		location.pathname.indexOf('om-') !== -1;
	const shouldShowLogoMobile =
		location.pathname === '/' || location.pathname === '/logga-in';

	const notAboutPage = location.pathname.indexOf('om-') === -1;

	return (
		<>
			{(shouldShowMenu || !onMobileDevice) && (
				<PageHeader showLogoMobile={shouldShowLogoMobile} user={user} />
			)}
			{notAboutPage && <Brain />}
			{children}
		</>
	);
};

export default StandardLayout;
