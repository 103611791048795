/**
 * LoginPage
 * @module pages/LoginPage
 */

import React, { useState } from 'react';
import './LoginPage.scss';
import { Form, Field, Button } from 'components/Form';
import { API_URL, TOKEN_NAME, USER_INFO } from 'common/config';
import { Redirect } from 'react-router-dom';
import fetch from 'unfetch';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import BrandHeader from 'components/BrandHeader';

const LoginPage = ({ location }) => {
	let { from } = location.state || { pathname: '/' };

	const [redirectToReferrer, setRedirectToReferrer] = useState(false);
	const [error, setError] = useState(false);
	const [message, setMessage] = useState(
		'Någonting gick fel vid din begäran, försök igen om en stund.'
	);

	const logInUser = values => {
		fetch(`${API_URL}/Auth/Login`, {
			body: JSON.stringify(values),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json-patch+json',
			},
			method: 'POST',
			credentials: 'same-origin',
		})
			.then(response => response.json())
			.then(response => {
				if (response.token) {
					const token = { token: response.token, expires: response.expire };
					localStorage.setItem(TOKEN_NAME, JSON.stringify(token));
					setRedirectToReferrer(true);
					getUserId();
				} else if (response.message) {
					setMessage(response.message);
					setError(true);
				}
			})
			.catch(error => {
				setError(true);
			});
	};

	const getUserId = () => {
		const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

		fetch(`${API_URL}/User/me`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
		})
			.then(response => response.json())
			.then(response => {
				localStorage.setItem(USER_INFO, JSON.stringify(response));
			})
			.catch(error => {
				setError(true);
			});
	};

	if (redirectToReferrer) {
		return <Redirect to={from} />;
	}

	return (
		<StandardLayout location={location}>
			<main id="main-content" className="loginpage">
				<Layout>
					<BrandHeader subtitle="Rapportering hjärnskakning" />

					<Form
						onSubmit={logInUser}
						initialValues={{ email: '', password: '' }}
						className="form loginpage__form form--center"
					>
						<div className="loginpage__input form__item--Text">
							<label htmlFor="email" className="is-visuallyhidden">
								Ange användarnamn (e-post)
							</label>
							<Field
								id="email"
								type="text"
								placeholder="Namn"
								name="email"
								required
							/>
						</div>
						<div className="loginpage__input form__item--Text">
							<label htmlFor="password" className="is-visuallyhidden">
								Ange lösenord
							</label>
							<Field
								id="password"
								type="password"
								placeholder="Lösenord"
								name="password"
								autoComplete="password"
								required
							/>
						</div>
						{error && <p className="loginpage__error">{message}</p>}
						<Button
							type="submit"
							className="form__button--Submit loginpage__button"
						>
							Logga in
						</Button>
					</Form>
				</Layout>
			</main>
		</StandardLayout>
	);
};

export default LoginPage;
