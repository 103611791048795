/**
 * AboutPage
 * @module pages/AboutPage
 */

import React from 'react';
import './AboutPage.scss';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import MobileHeader from 'components/MobileHeader';

const AboutPage = props => {
	const { params } = props.match;

	const title = params.id === 'projektet' ? 'Om projektet' : 'Om rapportering';

	return (
		<StandardLayout location={props.location}>
			<MobileHeader title={title} prevPath="/" />
			<main id="main-content" className="aboutpage">
				<Layout>
					{params.id === 'projektet' && (
						<div className="aboutpage__text">
							<p>
								Svenska Ishockeyförbundet är oroliga för den stora mängd
								hjärnskakningar som utövare drabbas av och den drastiska
								ökningen av hjärnskakningar de senaste åren inom svensk
								ishockey.
							</p>

							<p>
								Vi vill vända trenden och arbeta mot en noll-vision mot
								hjärnskakningar inom svensk ishockey.
							</p>
							<p>
								Vi är därmed i behov av att mäta och samla in data om antalet
								hjärnskakningar och i vilken situation dem uppkommer för att på
								effektivaste sätt arbeta för att vända trenden och arbeta för en
								noll-vision.
							</p>
							<p>
								{`Kontakta `}
								<a href="mailto:morgan.johansson@shl.se">
									morgan.johansson@shl.se
								</a>
								{` vid frågor.`}
							</p>
						</div>
					)}
				</Layout>
			</main>
		</StandardLayout>
	);
};

export default AboutPage;
