/**
 * Brain
 * @module components/Brain
 */

import React from 'react';
import './Brain.scss';

const Brain = () => {
	return (
		<img
			src="/assets/images/Brain.svg"
			alt=""
			aria-hidden="true"
			className="brain"
		/>
	);
};

export default Brain;
