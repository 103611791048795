import React, { useContext } from 'react';
import { FormContext } from 'context/form.context';
import { Button } from 'components/Form';
import { getFormattedDate } from 'common/helpers';

/**
 * Returns the currently selected option's value for the specified step in text
 * @param {Object} stepOptions - The specified step's form options
 * @param {String/Number} value - The specified step's current form value
 * @returns {String}
 */
const getSelectedOptionValue = (stepOptions, value) => {
	const selectedOption = stepOptions.filter(opt => opt.id === Number(value))[0];
	return selectedOption ? selectedOption.name : '-';
};

/**
 * ReportSummary
 * @module pages/ReportSummary
 */
const ReportSummary = ({ date, submitError }) => {
	const { questions, values } = useContext(FormContext);

	return (
		<div>
			<h2 className="form__subheading">Liga</h2>
			<p className="form__description">
				{getSelectedOptionValue(
					questions['leagueTypes'],
					values['leagueTypeId']
				)}
			</p>

			<h2 className="form__subheading">Spelarposition</h2>
			<p className="form__description">
				{getSelectedOptionValue(
					questions['reportPositions'],
					values['positionId']
				)}
			</p>

			<h2 className="form__subheading">Hjärnskakning</h2>
			<p className="form__description">
				{getSelectedOptionValue(
					questions['reportIncidents'],
					values['incidentId']
				)}
			</p>

			<h2 className="form__subheading">Skadetillfälle</h2>
			<p className="form__description">
				{getSelectedOptionValue(questions['reportTypes'], values['typeId'])}
			</p>

			<h2 className="form__subheading">Datum</h2>
			<p className="form__description">{getFormattedDate(date)}</p>
			<h2 className="form__subheading">Typ av händelse</h2>
			<p className="form__description">
				{getSelectedOptionValue(questions['reportEvents'], values['eventId'])}
			</p>

			{values['eventOther'] && values['eventId'] === '6' && (
				<p className="form__description">{values['eventOther']}</p>
			)}

			<div className="form--center reportpage__button">
				<Button type="submit" className="form__button--Submit">
					Bekräfta
				</Button>
				{submitError && (
					<p className="form__subheading">
						Något gick fel när formuläret försökte skickas. Försök igen om en
						liten stund.
					</p>
				)}
			</div>
		</div>
	);
};

export default ReportSummary;
