/**
 * UserPage
 * @module pages/UserPage
 */

import React from 'react';
import './UserPage.scss';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import MobileHeader from 'components/MobileHeader';

import { USER_INFO } from 'common/config';

const UserPage = props => {
	const onMobileDevice = window.matchMedia('(max-width: 767px)').matches;
	const user = JSON.parse(localStorage.getItem(USER_INFO));

	return (
		<StandardLayout location={props.location}>
			{onMobileDevice && <MobileHeader title="Användare" prevPath="/" />}
			<main id="main-content" className="userpage">
				<Layout>
					<div className="userpage__text">
						<p>
							Inloggad som:{' '}
							<span className="userpage__text--bold">{`${user.firstName} ${
								user.lastName
							}`}</span>
						</p>
						<p>Förening: {user.organization.name}</p>
					</div>
				</Layout>
			</main>
		</StandardLayout>
	);
};

export default UserPage;
