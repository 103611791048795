/**
 * Loader
 * @module components/Loader
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';
import Icon from 'components/Icon';

const Loader = props => {
	const { screenreaderText, size } = props;
	const classes = size ? `loader loader--${size}` : 'loader loader--small';

	return (
		<span className={classes}>
			<Icon icon="icon-spinner" />
			<span className="is-visuallyhidden">
				{screenreaderText || 'Laddar...'}
			</span>
		</span>
	);
};

Loader.propTypes = {
	screenreaderText: PropTypes.string,
	size: PropTypes.oneOf(['small', 'large']),
};

export default Loader;
