/**
 * Icon
 * @module components/Icon
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Icon.scss';

const Icon = props => {
	const { icon, color } = props;
	const className = color ? `icon ${icon} icon--${color}` : `icon ${icon}`;

	return <span className={className} />;
};

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	color: PropTypes.oneOf(['grey', 'white']),
};

export default Icon;
