/**
 * Button
 * @module components/Button
 */

import React from 'react';
import PropTypes from 'prop-types';

const Button = props => {
	const { type, onFieldChange, resetForm, ...propsToTransfer } = props;

	if (type.toLowerCase() === 'reset') {
		return (
			<button {...propsToTransfer} onClick={props.resetForm}>
				{props.children}
			</button>
		);
	} else {
		return <button {...propsToTransfer}>{props.children}</button>;
	}
};

Button.propTypes = {
	type: PropTypes.string,
	onFieldChange: PropTypes.func,
	resetForm: PropTypes.func,
};

Button.defaultProps = {
	type: 'button',
};

export default Button;
