/**
 * Checkbox
 * @module components/Checkbox
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Checks if the checkbox should be checked.
 */
const shouldBeChecked = (value, defaultValue) => {
	if (Array.isArray(defaultValue)) {
		return defaultValue.indexOf(value) !== -1;
	} else {
		return value === defaultValue;
	}
};

const Checkbox = props => {
	const { id, name, onFieldChange, autoSubmit, value } = props;
	const [checked, setChecked] = useState(
		shouldBeChecked(props.value, props.defaultValue)
	);

	const onChange = e => {
		setChecked(e.target.checked);
		onFieldChange({
			name,
			value: e.target.value,
			checked,
			autoSubmit,
			type: 'Checkbox',
		});
	};

	return (
		<input
			type="checkbox"
			id={id}
			name={name}
			value={value}
			checked={checked}
			onChange={onChange}
		/>
	);
};

Checkbox.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	autoSubmit: PropTypes.bool,
	onFieldChange: PropTypes.func,
};

export default Checkbox;
