/**
 * ConfirmationPage
 * @module pages/ConfirmationPage
 */

import React from 'react';
import './ConfirmationPage.scss';
import { TOKEN_NAME } from 'common/config';
import { checkTokenValidity } from 'common/helpers';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import Button from 'components/Button';

const logOut = () => localStorage.removeItem(TOKEN_NAME);

const ConfirmationPage = ({ location }) => {
	const isLoggedIn = checkTokenValidity();

	return (
		<StandardLayout location={location}>
			<main id="main-content" className="confirmationpage">
				<Layout>
					<h1 className="confirmationpage__heading">
						Vi har tagit emot din rapport.
					</h1>
					<p>
						Tack för att du bidrar till projektet, dina uppgifter är värdefulla!
					</p>
					<p>
						Du kommer att få kopia på de uppgifter till den e-postadress som
						finns registrerad i systemet.
					</p>
					<p>För att ändra uppgifter följ instruktionerna i mejlet.</p>
					<div className="confirmationpage__button">
						<Button type="route" url="/rapportera" color="blue">
							Ny rapport
						</Button>
						{isLoggedIn && (
							<Button type="link" url="/" color="blue" onClick={logOut}>
								Logga ut
							</Button>
						)}
					</div>
				</Layout>
			</main>
		</StandardLayout>
	);
};

export default ConfirmationPage;
