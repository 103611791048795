/**
 * StartPage
 * @module pages/StartPage
 */

import React from 'react';
import './StartPage.scss';
import { TOKEN_NAME, USER_INFO } from 'common/config';
import { checkTokenValidity } from 'common/helpers';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import Button from 'components/Button';
import Icon from 'components/Icon';
import BrandHeader from 'components/BrandHeader';

const logOut = () => {
	localStorage.removeItem(TOKEN_NAME);
	localStorage.removeItem(USER_INFO);
};

const StartPage = props => {
	const onMobileDevice = window.matchMedia('(max-width: 767px)').matches;

	const isLoggedIn = checkTokenValidity();

	return (
		<StandardLayout location={props.location}>
			<main id="main-content">
				<Layout>
					<BrandHeader subtitle="Rapportering hjärnskakning" />

					{onMobileDevice && (
						<div className="startpage__buttons">
							<Button type="route" url="om-projektet" color="blue">
								Om projektet
								<Icon icon="icon-chevron-right" />
							</Button>
							{isLoggedIn && (
								<Button type="link" url="/" color="blue" onClick={logOut}>
									Logga ut
								</Button>
							)}
						</div>
					)}

					{!onMobileDevice && (
						<>
							<section className="startpage__section">
								<h1 className="startpage__section__heading">Om projektet</h1>
								<p className="startpage__text">
									Svenska Ishockeyförbundet är oroliga för den stora mängd
									hjärnskakningar som utövare drabbas av och den drastiska
									ökningen av hjärnskakningar de senaste åren inom svensk
									ishockey.
								</p>

								<p className="startpage__text">
									Vi vill vända trenden och arbeta mot en noll-vision mot
									hjärnskakningar inom svensk ishockey.
								</p>
								<p className="startpage__text">
									Vi är därmed i behov av att mäta och samla in data om antalet
									hjärnskakningar och i vilken situation dem uppkommer för att
									på effektivaste sätt arbeta för att vända trenden och arbeta
									för en noll-vision.
								</p>
								<p className="startpage__text">
									{`Kontakta `}
									<a href="mailto:morgan.johansson@shl.se">
										morgan.johansson@shl.se
									</a>
									{` vid frågor.`}
								</p>
							</section>

							{isLoggedIn && (
								<div className="startpage__buttons">
									<Button type="link" url="/" color="blue" onClick={logOut}>
										Logga ut
									</Button>
								</div>
							)}
						</>
					)}
				</Layout>
			</main>
		</StandardLayout>
	);
};

export default StartPage;
