/**
 * Layout
 * @module components/Layout
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Layout.scss';

const Layout = props => {
	const { color, children } = props;
	const className = color ? `layout layout--${color}` : 'layout';

	return (
		<div className={className}>
			<div className="layout__content">{children}</div>
		</div>
	);
};

Layout.propTypes = {
	color: PropTypes.oneOf(['white', 'grey-light']),
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.object,
		PropTypes.array,
	]),
};

export default Layout;
