import { TOKEN_NAME } from 'common/config';

/**
 * Polyfill for forEach functions on objects to work on IE/Edge
 */
export const polyfillForEach = () => {
	if (
		typeof NodeList !== 'undefined' &&
		typeof NodeList.prototype.forEach !== 'function'
	) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
};

/**
 * Formats a date as DD Month YYYY
 * @param {Date} date - A date object
 */
export const getFormattedDate = date => {
	if (date) {
		const months = [
			'Januari',
			'Februari',
			'Mars',
			'April',
			'Maj',
			'Juni',
			'Juli',
			'Augusti',
			'September',
			'Oktober',
			'November',
			'December',
		];
		const monthIndex = date.getMonth() - 1;

		return `${date.getDate()} ${months[monthIndex + 1]} ${date.getFullYear()}`;
	} else {
		return '-';
	}
};

/**
 * Checks if token is still valid
 * @returns {Boolean} - true if still valid
 */
export const checkTokenValidity = () => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME));
	if (token) {
		const tokenExpires = Date.parse(token.expires);
		return tokenExpires > Date.now();
	} else {
		return false;
	}
};
