/**
 * Field
 * @module components/Field
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Field extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.defaultValue || '',
		};

		this.fieldRef = React.createRef();
	}

	componentDidUpdate() {
		if (this.props.setFocus) {
			this.fieldRef.current.focus();
		}
	}

	/**
	 * Callback method for onChange.
	 */
	onChange = e => {
		const value = e.target.value;
		const { name, onFieldChange, autoSubmit } = this.props;

		this.setState({ value });
		onFieldChange({ name, value, autoSubmit, type: 'Field' });
	};

	render() {
		const {
			className,
			type,
			id,
			name,
			placeholder,
			required,
			pattern,
			setFocus,
			disabled,
			onFieldChange,
			resetForm,
			defaultValue,
			description,
			min,
			max,
			...props
		} = this.props;
		const { value } = this.state;

		return (
			<input
				className={className || ''}
				type={type}
				id={id}
				title={description}
				name={name}
				required={required}
				disabled={disabled}
				placeholder={placeholder || ''}
				value={value}
				pattern={pattern}
				min={min}
				max={max}
				onChange={this.onChange}
				ref={this.fieldRef}
				{...props}
			/>
		);
	}
}

Field.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	id: PropTypes.string,
	description: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	pattern: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.any,
	]),
	autoSubmit: PropTypes.bool,
	setFocus: PropTypes.bool,
	onFieldChange: PropTypes.func,
	disabled: PropTypes.bool,
};

export default Field;
