/**
 * Button
 * @module components/Button
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import { Link } from 'react-router-dom';

const Button = props => {
	const { type, color, onClick, url, children } = props;
	const className = `button button--${color}`;

	if (type === 'link') {
		return (
			<a href={url} onClick={onClick} className={className}>
				{children}
			</a>
		);
	} else if (type === 'route') {
		return (
			<Link to={url} className={className} onClick={onClick}>
				{children}
			</Link>
		);
	} else {
		return (
			<button type="button" onClick={onClick} className={className}>
				{children}
			</button>
		);
	}
};

Button.propTypes = {
	type: PropTypes.oneOf(['link', 'button', 'route']),
	color: PropTypes.oneOf(['blue', 'white']),
	url: PropTypes.string,
	onClick: PropTypes.func,
};

Button.defaultProps = {
	type: 'link',
	color: 'green',
	url: '#',
};

export default Button;
