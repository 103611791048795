/**
 * HistoryPage
 * @module pages/HistoryPage
 */

import React, { useState, useEffect } from 'react';
import './HistoryPage.scss';
import {
	Table,
	TableHead,
	TableHeading,
	TableBody,
} from 'components/Table/index.js';
import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import MobileHeader from 'components/MobileHeader';

import fetch from 'unfetch';
import { API_URL, TOKEN_NAME, USER_INFO } from 'common/config';

const HistoryPage = props => {
	const onMobileDevice = window.matchMedia('(max-width: 767px)').matches;
	const [reports, setReport] = useState([]);

	const loadReports = () => {
		const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;
		const orgId = JSON.parse(localStorage.getItem(USER_INFO)).organization.id;

		fetch(
			`${API_URL}/Report?organizationId=${orgId}&orderBy=leagueType&orderBy=date`,
			{
				cache: 'no-cache',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json-patch+json',
				},
				method: 'GET',
				credentials: 'same-origin',
			}
		)
			.then(response => response.json())
			.then(response => {
				setReport(response.items);
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		loadReports();
	}, []);

	return (
		<StandardLayout location={props.location}>
			{onMobileDevice && (
				<MobileHeader title="Föreningshistorik" prevPath="/" />
			)}
			<main id="main-content" className="historypage">
				<section className="historypage__section">
					<Layout>
						<h1 className="historypage__heading">Inrapporterade händelser</h1>
						<Table caption="Inrapporterade skador för din förening">
							<TableHead>
								<TableHeading text="Liga" />
								<TableHeading text="Position" />
								<TableHeading text="Status" />
								<TableHeading text="Skadetillfälle" />
								<TableHeading text="Datum" />
								<TableHeading text="Händelse" />
							</TableHead>
							<TableBody>
								{reports && reports.length > 0 ? (
									reports.map((report, i) => (
										<tr key={i}>
											<td>{report.leagueType.name}</td>
											<td>{report.position.name}</td>
											<td>{report.incident.name}</td>
											<td>{report.type.name}</td>
											<td className="historypage__date">
												{report.date.slice(0, 10)}
											</td>
											<td>{report.event.name}</td>
										</tr>
									))
								) : (
									<tr>
										<td>Inga rapporter att visa.</td>
										<td />
										<td />
										<td />
										<td />
										<td />
										<td />
										<td />
									</tr>
								)}
							</TableBody>
						</Table>
					</Layout>
				</section>
			</main>
		</StandardLayout>
	);
};

export default HistoryPage;
