/**
 * TextArea
 * @module components/Form/TextArea
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextArea extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.defaultValue || '',
		};

		this.fieldRef = React.createRef();
	}

	componentDidUpdate() {
		if (this.props.setFocus) {
			this.fieldRef.current.focus();
		}
	}

	/**
	 * Callback method for onChange.
	 */
	onChange = e => {
		const value = e.target.value;
		const { name, onFieldChange, autoSubmit } = this.props;

		this.setState({ value });
		onFieldChange({ name, value, autoSubmit, type: 'TextArea' });
	};

	render() {
		const {
			className,
			type,
			id,
			description,
			name,
			placeholder,
			required,
			disabled,
		} = this.props;
		const { value } = this.state;

		return (
			<textarea
				className={className || ''}
				type={type}
				id={id}
				title={description}
				name={name}
				required={required}
				disabled={disabled}
				placeholder={placeholder || ''}
				value={value}
				onChange={this.onChange}
				ref={this.fieldRef}
			/>
		);
	}
}

TextArea.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	description: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	autoSubmit: PropTypes.bool,
	setFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	onFieldChange: PropTypes.func,
};

export default TextArea;
