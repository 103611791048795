/**
 * Table
 * @module components/Table
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Table.scss';

const Table = ({ children, caption }) => {
	return (
		<div className="table__wrapper">
			<table className="table">
				{caption && <caption className="table__caption">{caption}</caption>}
				{children}
			</table>
		</div>
	);
};

Table.propTypes = {
	caption: PropTypes.string,
};

export default Table;
