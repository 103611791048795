/**
 * MobileHeader
 * @module components/MobileHeader
 */

import React from 'react';
import PropTypes from 'prop-types';
import './MobileHeader.scss';

import Layout from 'components/Layout';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';

const MobileHeader = props => {
	const { title, prevPath, onClick } = props;

	return (
		<div className="mobile-header">
			<Layout>
				<div className="mobile-header__content">
					<Link
						to={prevPath}
						title="Gå tillbaka till föregående sida"
						onClick={onClick}
					>
						<Icon icon="icon-arrow-left" color="white" />
					</Link>
					<h1 className="mobile-header__heading">{title}</h1>
				</div>
			</Layout>
		</div>
	);
};

MobileHeader.propTypes = {
	title: PropTypes.string,
	prevPath: PropTypes.string,
	onClick: PropTypes.func,
};

export default MobileHeader;
