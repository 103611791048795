import React from 'react';
import 'styles/global.scss';
import { polyfillForEach, checkTokenValidity } from 'common/helpers';
import { Route, Switch, Redirect } from 'react-router-dom';
import { FormProvider } from 'context/form.context';

import StartPage from 'pages/StartPage';
import AboutPage from 'pages/AboutPage';
import LoginPage from 'pages/LoginPage';
import ReportPage from 'pages/ReportPage';
import HistoryPage from 'pages/HistoryPage';
import UserPage from 'pages/UserPage';
import ConfirmationPage from 'pages/ConfirmationPage';
import ErrorPage from 'pages/ErrorPage';

polyfillForEach();

/**
 * Handling of private routes
 */
const PrivateRoute = ({ component: Component, openModal, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				checkTokenValidity() ? (
					<Component {...props} openModal={openModal} />
				) : (
					<Redirect
						to={{
							pathname: '/logga-in',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

const App = () => {
	return (
		<FormProvider>
			<Switch>
				<Route exact path="/" component={StartPage} />
				<Route path="/om-:id" component={AboutPage} />
				<Route path="/logga-in" component={LoginPage} />
				<PrivateRoute path="/historik" component={HistoryPage} />
				<PrivateRoute path="/anvandare" component={UserPage} />
				<PrivateRoute path="/rapportera" component={ReportPage} />
				<PrivateRoute path="/rapportera-:id" component={ReportPage} />
				<PrivateRoute exact path="/bekraftelse" component={ConfirmationPage} />
				<Route component={ErrorPage} />
			</Switch>
		</FormProvider>
	);
};

export default App;
