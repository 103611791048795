import { useState, useContext } from 'react';
import { API_URL, TOKEN_NAME } from 'common/config';
import { FormContext } from 'context/form.context';
import fetch from 'unfetch';

/**
 * Custom hook for a step counter
 * Returns step, update function and next/previous step index
 * @param {Number} defaultNr - Custom number to start counting from
 */
export const useStepCounter = defaultNr => {
	const [step, setStep] = useState(Number(defaultNr) || 0);

	const nextStepNr = Number(step) + 1;
	let previousStepNr = Number(step) - 1;

	if (previousStepNr === 0) {
		previousStepNr = 1;
	}

	return [step, setStep, nextStepNr, previousStepNr];
};

/**
 * Submits form and creates new report
 * @param {Object} history
 * @param {Object} values - Form values
 * @param {String} redirectTo - Url to redirect to after submit
 */
export const useFormSubmit = history => {
	const { setValues } = useContext(FormContext);
	const [error, setError] = useState(false);

	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	const submitForm = (values, redirectTo) => {
		fetch(`${API_URL}/Report`, {
			body: JSON.stringify(values),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'POST',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => {
				if (response.status === 200) {
					setValues({});
					setError(false);
					history.push(redirectTo);
				} else {
					setError(true);
				}
			})
			.catch(error => {
				setError(true);
			});
	};

	return [error, submitForm];
};
