/* eslint-disable jsx-a11y/no-access-key */
/**
 * PageHeader
 * @module components/PageHeader
 */

import React from 'react';
import PropTypes from 'prop-types';
import './PageHeader.scss';
import { Link } from 'react-router-dom';

import Layout from 'components/Layout';
import MainMenu from 'components/MainMenu';
import Icon from 'components/Icon';

const PageHeader = React.memo(({ showLogoMobile, user }) => {
	const onMobileDevice = window.matchMedia('(max-width: 767px)').matches;
	const shouldShowLogo = (onMobileDevice && showLogoMobile) || !onMobileDevice;

	return (
		<header className="page-header">
			<a
				href="#main-content"
				accessKey="s"
				className="is-visuallyhidden focusable"
			>
				Hoppa till huvudinnehållet
			</a>
			<Layout>
				<div className="page-header__content">
					{shouldShowLogo && (
						<div className="page-header__logo">
							<Link to="/">
								<img
									src="/assets/images/swehockey_logo.svg"
									alt="Svenska Ishockeyförbundet logotyp"
								/>
							</Link>
						</div>
					)}
					<MainMenu />

					{!onMobileDevice && user && (
						<div className="page-header__user">
							<p className="page-header__text page-header__text--bold">
								<Icon icon="icon-user" />
								{`${user.firstName} ${user.lastName}`}
							</p>
							<p className="page-header__text">{user.organization.name}</p>
						</div>
					)}
				</div>
			</Layout>
		</header>
	);
});

PageHeader.propTypes = {
	showLogoMobile: PropTypes.bool,
};

export default PageHeader;
