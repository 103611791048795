/**
 * BrandHeader
 * @module components/BrandHeader
 */

import React from 'react';
import PropTypes from 'prop-types';
import './BrandHeader.scss';

const BrandHeader = React.memo(({ subtitle }) => {
	return (
		<div className="brand-header">
			<h1 className="brand-header__heading">
				Svenska
				<br />
				Ishockeyförbundet
			</h1>
			{subtitle && <h2 className="brand-header__subheading">{subtitle}</h2>}
		</div>
	);
});

BrandHeader.propTypes = {
	subtitle: PropTypes.string,
};

export default BrandHeader;
