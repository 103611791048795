/**
 * TableBody
 * @module components/TableBody
 */

import React from 'react';
import './Table.scss';

const TableBody = ({ children }) => {
	return <tbody className="table__body">{children}</tbody>;
};

export default TableBody;
