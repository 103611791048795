/**
 * Select
 * @module components/Select
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Icon from 'components/Icon';

class Select extends Component {
	/**
	 * Callback method for onChange.
	 */
	onChange = e => {
		const { name, onFieldChange, autoSubmit, multiple } = this.props;
		let value = '';

		if (multiple) {
			value = this.getSelectValues(e.target);
		} else {
			const selectedOption = e.target.options[e.target.selectedIndex];
			value = selectedOption.value || selectedOption.text;
		}

		onFieldChange({ name, value, autoSubmit });
	};

	/**
	 * Gets the selected values for both single and multiple selects.
	 */
	getSelectValues(select) {
		const result = [];
		const options = select.options;
		let opt;

		for (let i = 0, iLen = options.length; i < iLen; i++) {
			opt = options[i];

			if (opt.selected) {
				result.push(opt.value || opt.text);
			}
		}
		return result;
	}

	/**
	 * Formats the select options to work with ReactSelect component
	 * @param {Array} options
	 */
	formatMultiselectOptions(options) {
		return options.map(option => {
			return {
				value: option.id,
				label: option.name,
			};
		});
	}

	/**
	 * Renders an option for the select.
	 */
	renderOptions(option) {
		const { id, name, disabled } = option;

		return (
			<option value={id} key={id} disabled={disabled}>
				{name}
			</option>
		);
	}

	/**
	 * Renders a custom multiselect component using ReactSelect
	 */
	renderMultiselect() {
		const { name, options, defaultValue, placeholder } = this.props;

		const formattedOptions = this.formatMultiselectOptions(options);
		const defaultValues = defaultValue.map(option => {
			return formattedOptions.filter(opt => opt.value === option)[0];
		});

		return (
			<ReactSelect
				className="form__item--Multiple"
				classNamePrefix="select"
				defaultValue={defaultValues}
				options={formattedOptions}
				isMulti
				name={name}
				placeholder={placeholder || 'Välj ett alternativ'}
				noOptionsMessage={() => 'Inga alternativ'}
				loadingMessage="Laddar"
			/>
		);
	}

	render() {
		const {
			id,
			name,
			options,
			multiple,
			defaultValue,
			description,
		} = this.props;

		if (multiple) {
			return this.renderMultiselect();
		}

		return (
			<span className="select-single">
				<select
					id={id}
					name={name}
					title={description}
					multiple={multiple}
					defaultValue={defaultValue}
					onChange={this.onChange}
				>
					{options.map(this.renderOptions)}
				</select>
				<Icon icon="icon-chevron-down" />
			</span>
		);
	}
}

Select.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	description: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	multiple: PropTypes.bool,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	autoSubmit: PropTypes.bool,
	onFieldChange: PropTypes.func,
};

export default Select;
